import api from '@/services/api';

const url = 'institutional-text';

const getInstitutionalText = () => api.get(url);

const createInstitutionalText = (institutionalText) => api.post(url, institutionalText);

const updateInstitutionalText = (institutionalText) => api.put(url, institutionalText);

const deleteInstitutionalText = (id) => api.delete(`${url}/${id}`);

export default {
  getInstitutionalText, createInstitutionalText, updateInstitutionalText, deleteInstitutionalText,
};
