<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :search="search"
      :items="institutionalText"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Textos Institucionais</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar "
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              @click="prepareToDelete(item)"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-textinstitucional
        v-if="dialog"
        :populate-with="institutionalTextToEdit"
        @institutionalText-added="addInstitutionalText"
        @institutionalTex-edited="editInstitutionalText"
        @close-dialog="closeDialog"
      />
    </v-dialog>
    <delete-confirmation
      :visible="dialogDeleteConfirmation"
      @cancel="dialogDeleteConfirmation = false"
      @confirm="deleteItem()"
    />
  </div>
</template>

<script>
import InstitutionalTextService from '../../../services/institutional-text.service';
import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'InstitucionalText',
  components: {
    'form-textinstitucional': () => import('./Form'),
    DeleteConfirmation,
  },

  data: () => ({
    headers: [
      {
        text: 'Titulo Institucional',
        sortable: false,
        value: 'title',
      },
      {
        text: 'Categoria',
        sortable: false,
        value: 'category.name',
      },
      {
        text: 'Ações',
        value: 'actions',
        sortable: false,
      },
    ],

    categories: [],
    institutionalText: [],
    search: '',
    dialog: false,
    institutionalTextToEdit: {},
    dialogDeleteConfirmation: false,
  }),

  async created() {
    await this.loadInstitutionalText();
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.institutionalTextToEdit = {};
    },

    async loadInstitutionalText() {
      const data = await InstitutionalTextService.getInstitutionalText();
      this.institutionalText = data;
    },

    addInstitutionalText(institutionalText) {
      this.institutionalText.push(institutionalText);
      this.dialog = false;
      this.loadInstitutionalText();
    },

    prepareToDelete(item) {
      this.dialogDeleteConfirmation = true;
      this.deleteItemID = item.id;
    },

    async deleteItem() {
      try {
        await InstitutionalTextService.deleteInstitutionalText(this.deleteItemID);
        const index = this.institutionalText.findIndex((listInstitutionalText) => listInstitutionalText.id === this.deleteItemID);
        this.institutionalText.splice(index, 1);
        this.dialogDeleteConfirmation = false;
        this.$toast.success('Texto excluído com sucesso');
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    editInstitutionalText(institutionalText) {
      const index = this.institutionalText.findIndex((listInstitutionalText) => listInstitutionalText.id === institutionalText.id);
      this.institutionalText[index] = institutionalText;
    },

    editItem(institutionalText) {
      this.institutionalTextToEdit = institutionalText;
      this.dialog = true;
    },

  },
};
</script>
